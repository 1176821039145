// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
// TODO: 052223 - Rename this file to dependencies.js
/*
ISSUES:
BUG: 062223 - Visibility Dependency Selector empty under certain circumstances.
*/
/*eslint-disable*/
import { useState, useContext } from "react";
import { Grid, FormControl } from "@mui/material";
import Debug from "../../services/debug";
import { AuthContext } from "../../services/context";
import FormSelect from "../../components/Select";
import * as utils from "../../services/utilities";
import * as dependencyUtil from "./dependency";

export const Selector = ({
  context,
  questions,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  // utils.log.component("dependencySelector.js - Selector (render)", {
  //   questions,
  //   context,
  //   selectedQuestion
  // });
  // #region Assertions
  utils.log.info("^^ Selector Choice", context);
  utils.assert(context, "context is required");
  const { surveyService } = useContext(AuthContext);
  //Step - 1 :: get dependency from original Questions ,that is dependency questions from Original Questions
  const dependency = dependencyUtil.get(context);
  // utils.log.info(":: CCQ-2-dependecy in DependecySelector or Selector", {dependency});
  // Include only questions before the current question
  //Step - 2 :: Filtered all Questions above this question
  const questionsFiltered = questions.filter(
    (q) =>
      questions.indexOf(q) <
      questions.findIndex((q) => q.code === context.question.code)
  );

  const handleQuestionChange = (e) => {
    const question = questions.find((q) => q.code === e.target.value); // Question(selected) to depend on

    if (!question) {
      // No question selected (- None -)
      setSelectedQuestion((prev) => ({ ...prev, dependency: null }));
    } else {
      //set dependency in Original Questions in which we select dependency
      const questionDependency = surveyService
        .typeLookup(question.type)
        .dependencyNew(question, null); // Create question dependency only
      setSelectedQuestion((prev) => ({
        ...prev,
        dependency: questionDependency,
      }));
    }
  };

  const questionType = selectedQuestion?.dependency
    ? surveyService.typeLookup(selectedQuestion.dependency.question.type)
    : null;

  // #endregion
  return (
    <FormControl sx={{ m: 1, minWidth: 120, width: "100%" }}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          Visibility Dependency
        </Grid>
        <Grid item xs={12}>
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              gap: "20px",
            }}
          > */}
            <QuestionSelector
              context={context}
              questions={questionsFiltered}
              /* dependency={dependency} */
              selectedQuestion={selectedQuestion}
              handleQuestionChange={handleQuestionChange}
            />
            {/* Selected other things i.e Choice and Category depend on Question Type  */}
            {selectedQuestion?.dependency && // Only render dependencySelector if question selected
              questionType.dependencySelector({
                context: context,
                selectedQuestion: selectedQuestion,
              })}{" "}
            {/* <Debug value={dependency}></Debug> */}
          {/* </div> */}
        </Grid>
      </Grid>
    </FormControl>
  );
};

export const QuestionSelector = ({
  context,
  questions,
  selectedQuestion,
  handleQuestionChange,
}) => {
  utils.log.info(":: QuestionSelector", {
    context,
    questions,
    selectedQuestion,
  });
  const questionsFiltered = questions.filter(
    (q) => q.code !== context.question.code
  ); // Prevents selecting self as dependency - Doesn't really do anything now that the Selector (parent) only sends questions before this one.

  const list = utils.toSelectItem(
    questionsFiltered,
    "@['type'] (@['code'])",
    "code",
    true
  );
  // #endregion
  return (
    <>
      <FormSelect
        id="dropdownQuestions"
        label="Questions"
        data={list}
        value={
          selectedQuestion?.dependency?.question?.code ||
          utils.noSelection.value
        }
        allowNoSelection={utils.noSelection}
        onChange={handleQuestionChange}
      ></FormSelect>
    </>
  );
  // #endregion
};

// PURPOSE: Renders a select list of choices for the selected question.
export const ChoiceSelector = ({ context, selectedQuestion }) => {
  utils.assert(context, "context is required");
  utils.assert(selectedQuestion, "selectedQuestion is required");

  //Doubt :: What is use to call dependencyUtil.get() in below line?
  const dependency = dependencyUtil.get(context);
  const isQuestionSelected = selectedQuestion != null; // Question selected
  const isDisabled = !isQuestionSelected;
  const choices = isQuestionSelected
    ? selectedQuestion.dependency.question.choices // If question selected, use its choices
    : dependency.question.choices; // If no question selected, use dependency question's choices

  const list = isQuestionSelected
    ? utils.toSelectItem(choices, "@['code']: @['value']", "code", true)
    : [];

  utils.log.info(":: list", list);

  const [selectedChoiceCode, setSelectedChoiceCode] = useState(
    selectedQuestion?.dependency?.choice?.code || utils.noSelection.value
  ); // Set to dependency/null as initial

  const handleChange = (e) => {
    const choice =
      choices.find((c) => c.code === e.target.value) ?? utils.noSelection.value;
    /*     dependencyUtil.set(
      context,
      dependencyUtil.create(selectedQuestion, choice)
    ); */
    const dependency = dependencyUtil.get(context);
    if (choice === utils.noSelection.value) dependency.choice = null;
    else dependency.choice = choice;
    dependencyUtil.set(context, dependency);
    if (choice != null) {
      setSelectedChoiceCode(choice.code);
    }
  };
  /* useEffect(() => {}, [selectedChoiceCode]);
  // utils.log.useEffect(
    `ChoiceSelector(): selectedChoiceCode`,
    selectedChoiceCode
  ); */
  // #endregion

  return (
      <FormSelect
        id="dropdownChoices"
        disabled={isDisabled}
        // id="choice-select"
        label="Choices"
        data={list}
        value={selectedChoiceCode}
        allowNoSelection={utils.noSelection}
        debug
        onChange={handleChange}
      ></FormSelect>
  );
};

export function ValidateContext(
  context,
  dependency,
  handleValid,
  handleInvalid
) {
  // PURPOSE: Ensures that the context contains a question and optional choice.
  try {
    // Question (required)
    if (!context.hasOwnProperty("question") || context.question == null)
      throw new Error("Question is required");
    // Choice (property required - nullable)
    if (!context.hasOwnProperty("choice"))
      throw new Error("Choice property is required, but can be null");
    // Type required (one of: question, choice, none)
    if (!context.hasOwnProperty("type") || context.type == null)
      throw new Error("Type required (one of: question, choice, none)");
    if (
      context.type !== "question" &&
      context.type !== "choice" &&
      context.type !== "none"
    )
      throw new Error("Type must be one of: question, choice, none");

    // Valid context
    if (handleValid !== undefined) handleValid(context, dependency);
  } catch (e) {
    console.error(`ValidateContext: ${e}`, context);

    if (handleInvalid !== undefined) handleInvalid(context, dependency, e);
  }
}

export const Context = (question, choice) => {
  // PURPOSE: Create a dependency context (question or choice). The context is the question or choice that the dependency is being assigned to.
  if (question == null) throw new Error("Question is required");
  choice ??= null;
  return {
    question: question,
    choice: choice,
    type: dependencyUtil.getType({ question: question, choice: choice }),
  };
};
/* 
FIXED
052823(052023) - Selecting NONE in ChoiceSelector component does not save.  Selecting a choice does.
  STEPS
    1. Go into a survey and select a choice based question that is not the first.
    2. Go into a choice and set a dependency for an earlier question.
    3. Click Update to exit question.
    4. Click Update to save survey.
  EXPECTED
    1. The dependency is saved.
  ACTUAL
    1. The dependency is not saved.
  CAUSE
    In ChoiceSelector.handleChoiceChange(), was not completed and the dependency is not saved.
  RESOLUTION
    Complete ChoiceSelector.handleChoiceChange() to save the dependency.
  FIX
    In ChoiceSelector.handleChoiceChange(), added code to save the dependency.

*/
