// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES:

*/
import React, { useState } from "react";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid, FormHelperText } from "@mui/material";
import * as dependencyUtil from "./dependency";
import * as utils from "../../services/utilities";
import { updateQuestionAnswer } from "../../services/question";
// import Debug from "../../services/debug";
import { QuestionHeader } from "../recipients/question";
import FormUtility from "../../services/form";
import errorIcon from "../../assets/errorIcons.svg";
// import useMediaQuery from "../../hooks/useMediaQuery";

const formUtility = new FormUtility();
// export const comparators = ["equals", "contains", "startsWith", "endsWith"];
export const comparators = ["None","equals", "contains", "startsWith", "endsWith"];
export function New() {
  return {
    type: "TextInput",
    code: null,
    title: "New Text Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    minLength: 1,
    maxLength: 10,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  utils.assert(question != null, "question is null.");
  // utils.log.component(`TextInput.Reset(${question.code})`, question);
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // #region Initialize

  formUtility?.setDetail(question);
  // TODO: Parameterize value label.
  // Validate min/max length exists
  if (question.minLength == null) question.minLength = 1; //throw new Error("TextInput.Edit(required): MinLength not found.");
  if (question.maxLength == null) question.maxLength = 10; //throw new Error("TextInput.Edit(required): MaxLength not found.");

  // #endregion
  // #region Events
  const handleMinLengthChange = (e) => {
    const value = e.target.value;
    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Minimum must be a number.", { variant: "error" });
    else if (value <= 0)
      // Negative value
      enqueueSnackbar("Minimum must be greater than zero.", {
        variant: "error",
      });
    else {
      // Valid value
      question.minLength = Number(value);
    }
    question.minLength = Number(value);
  };
  const handleMaxLengthChange = (e) => {
    const value = e.target.value;
    const minLength = question.minLength;

    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Maximum must be a number.", { variant: "error" });
    else if (value <= 0)
      // Negative value
      enqueueSnackbar("Maximum must be greater than zero.", {
        variant: "error",
      });
    else if (value < minLength)
      // Greater than max
      enqueueSnackbar("Maximum must be greater than maximum.", {
        variant: "error",
      });
    // Valid value
    else {
      question.maxLength = Number(value);
    }

    question.maxLength = Number(value);
  };

  const handleChange = (e) => {
    if (e.target.name === "minLength") {
      if (e.target.value > formUtility.getValue("maxLength")) {
        return enqueueSnackbar(
          "Minimum must be less than or equal to Maximum",
          { variant: "error" }
        );
      }
    }
    if (e.target.name === "maxLength") {
      if (e.target.value < formUtility.getValue("minLength")) {
        return enqueueSnackbar(
          "Maxmimum must be greater than or euqal to Minimum",
          {
            variant: "error",
          }
        );
      }
    }
    formUtility.handleChange(e, setInputs);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          id="non-linear-slider"
          gutterBottom
          style={{ fontFamily: "Public-sans" }}
        >
          Length:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} >
        {/*         <TextField
          label="Minimum"
          defaultValue={minLength}
          onBlur={handleMinLengthChange}
        ></TextField> */}
        <Input
          label="Minimum"
          fullWidth
          name="minLength"
          // onChange={(event) => formUtility.handleChange(event, setInputs)}
          onChange={handleChange}
          onBlur={handleMinLengthChange}
          value={formUtility.getValue("minLength")}
          type={"number"}
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
          }}
          id="textboxMinimum"
        />
      </Grid>
      <Grid item xs={12} sm={6} >
        {/* <TextField label="Maximum" defaultValue={maxLength}></TextField> */}
        <Input
          label="Maximum"
          fullWidth
          name="maxLength"
          // onChange={(event) => formUtility.handleChange(event, setInputs)}
          onChange={handleChange}
          onBlur={handleMaxLengthChange}
          value={formUtility.getValue("maxLength")}
          type={"number"}
          inputProps={{
            pattern: "[0-9]*",
            inputMode: "numeric",
          }}
          id={"textboxMaximum"}
        />
      </Grid>
    </Grid>
  );
}

export const DependencySelector = ({
  context,
  selectedQuestion,
  formUtility,
  inputs,
  setInputs,
  ...rest
}) => {
  // !NOTE: useState() is prohibited in this component.
  return (
    <TextComparator
      context={context}
      selectedQuestion={selectedQuestion}
      formUtility={formUtility}
      inputs={inputs}
      setInputs={setInputs}
    />
  );
};

const TextComparator = ({context,selectedQuestion,formUtility,inputs,setInputs}) =>{

  const dependency = dependencyUtil.get(context);
  const list = utils.toSelectItem(comparators);
  const [selectedComparator,setSelectedComparator] = useState(selectedQuestion?.dependency?.comparator)

  if (dependency && !dependency.value) {
    // Add a value if it doesn't exist
    dependency.comparator = list[0].value;
    dependency.value = null; //setDependency({ ...dependency, comparator: list[0].value, value: "" });
  }

  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    const comparator = e.target.value;
    dependency.comparator = comparator;
    dependencyUtil.set(context, dependency);
    setSelectedComparator(comparator);
    //comparator = dependency.comparator;
  };
  const handleValueChange = (e) => {
    const dependency = dependencyUtil.get(context);
    dependency.value = e.target.value;
    dependencyUtil.set(context, dependency);
  };

  utils.log.info(":: TextComparator",selectedQuestion);

  return(
    <>
    <FormSelect
      // id="textInput-comparators"
      id="dropdownComparators"
      label="Comparators"
      data={list}
      value={selectedComparator}
      style={{ width: "120px" }}
      onChange={handleComparatorChange}
      debug
   />
    {/* </div> */}
   { selectedComparator !== "None" && 
   <Input
      id="textInput-value"
      label="Value"
      name="value"
      style={{ width: "120px" }}
      onChange={handleValueChange}
      /* defaultValue={formUtility.getValue("value")} */
      defaultValue={dependency?.value || dependency?.choice?.value}
      onFocus={(e) => e.currentTarget.select()}
      fullWidth
    />}
  </>
  )
}

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.comparator = comparators[0];
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  // utils.log.component("TextInput.Render()");
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  const maxLength = question.maxLength ?? 0;
  const textBoxWidth = utils.getTextBoxWidth(maxLength);
  const answer = question.answer ?? ""; //const [value, setValue] = useState("");
  // BUG: 062623 Validation message does not update in preview.  When using state variable, error is caused by dependent isAnswered(presumed).
  // #endregion
  // #region Events
  const handleChange = (e) => {
    const value = e.target.value;
    // utils.log.event(`handleChange(e): ${value}`);
    updateQuestionAnswer(question, value, preview, props.setQuestions);
  };

  const isQuestionCompleted = isCompleted(props.question);
  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          {/* <div>{helperText}</div> */}
          <QuestionHeader question={question}></QuestionHeader>
          <Input
            id="textInput-value"
            key={`textInput-value.${question.code}`}
            label="Value"
            name="value"
            onChange={handleChange}
            style={{
              maxWidth: { textBoxWidthFormatted: textBoxWidth },
              width: "300px",
              background: "#F8F8F8",
            }}
          ></Input>
        </div>
      </div>

      {typeof answer === "string" && answer?.trim()?.length > maxLength && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop: preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            Maximum Length message is reached
          </FormHelperText>
        </div>
      )}
    </>
  );
}
// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  if (dependency == null) {
    return false;
  }
  const {question} = dependency || {};
  const {answer} = question || {};
  if(!answer)return false;

  // *** Evaluate ***
  //-None- is here is same as null in other question
  switch (dependency.comparator) {
    case "None":
      return handleComparatorNulls(dependency);
    case "equals":
      return handleComparatorEquals(dependency);
    case "contains":
      return handleComparatorContains(dependency);
    case "startsWith":
      return handleComparatorStartsWith(dependency);
    case "endsWith":
      return handleComparatorEndsWith(dependency);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}

function isAnswerInRange(answer, minLength, maxLength) {
  const trimmedAnswer = answer?.trim();
  return (
    trimmedAnswer &&
    trimmedAnswer.length >= Number(minLength) &&
    trimmedAnswer.length <= Number(maxLength)
  );
}

function handleComparatorNulls(dependency) {
  const { answer, minLength, maxLength } = dependency?.question || {};
  return isAnswerInRange(answer, minLength, maxLength);
}

function handleComparatorEquals(dependency) {
  const { answer, minLength, maxLength } = dependency?.question || {};
  const { value } = dependency || {};
  if (!isAnswerInRange(answer, minLength, maxLength)) return false;

  return answer.trim().toUpperCase() === value?.trim()?.toUpperCase();
}

function handleComparatorContains(dependency) {
  const { answer, minLength, maxLength } = dependency?.question || {};
  const { value } = dependency || {};
  if (!isAnswerInRange(answer, minLength, maxLength)) return false;

  return answer.trim().toUpperCase().includes(value?.trim()?.toUpperCase());
}

function handleComparatorStartsWith(dependency) {
  const { answer, minLength, maxLength } = dependency?.question || {};
  const { value } = dependency || {};
  if (!isAnswerInRange(answer, minLength, maxLength)) return false;

  return answer.trim().toUpperCase().startsWith(value?.trim()?.toUpperCase());
}

function handleComparatorEndsWith(dependency) {
  const { answer, minLength, maxLength } = dependency?.question || {};
  const { value } = dependency || {};
  if (!isAnswerInRange(answer, minLength, maxLength)) return false;

  return answer.trim().toUpperCase().endsWith(value?.trim()?.toUpperCase());
}


// PURPOSE: TO CHECK WHETHER THIS QUESTION IS COMPLETED OR NOT
export const isCompleted = (question) => {
  if (!question) {
    return false;
  }
  utils.log.info(":: question in isCompleted text-input question",question);
  // Also check if the answer is at least the minimum length and atmost the max length
  return (
    typeof question?.answer === "string" &&
    question.answer != null &&
    Number(question?.answer?.trim()?.length) >= Number(question?.minLength) &&
    Number(question?.answer?.trim()?.length) <= Number(question?.maxLength)
  );
};

export const getValue = (question) => {
  return {
    value: question?.answer || "",
  };
};

// #endregion
/* 
FIXED
042123 - DependencySelector - Value property is not being retrieved correctly after earlier set.
  STEPS:
    1. From survey questions, select question that is after a question of type Text Input (e.g. S: TEST Q: Q6).
		2. In Visibility Dependency, select a Text Input type (e.g. S: TEST Q: Q5).
		3. In Comparators dropdown, select an option other than "equals".
    4. Click the Update button.
    5. Exit the question.
    6. Open another question and exit again (need to fix deselection bug).
    7. Re-enter the question.
  EXPECTED    
    * The value should reflect what was entered earlier.
  CHECKS:
    * dependencyUtil.set(context, dependency)
      * Is it setting the dependency correctly in handleComparatorChange()?
        * 042123 (RHYSS) - Verified working correctly.

    * dependencyUtil.get(context)
      * Is it retrieving the dependency correctly?

  CAUSE:
    * In DependencySelector() Initialize region, there was an IF block that was intended
      to set the dependency value to an empty string if it was null. 
      However, it was using a dependencyUtil.create() erroneously.
  RESOLUTION:
    * Changed IF block clause and action taken.
      ORIGINAL:
		if (dependency?.value == null)
			dependencyUtil.set(context, dependencyUtil.create(selectedQuestion, null));
      CHANGE:
		if (dependency != null && dependency?.value == null)
			// Add a value if it doesn't exist
			dependency.value = ""; 
      
042123 - DependencySelector - Comparator dropdown not reflecting choice user just made.
	STEPS: 
		1. From survey questions, select question that is after a question of type Text Input (e.g. S: TEST Q: Q6).
		2. In Visibility Dependency, select a Text Input type (e.g. S: TEST Q: Q5).
		3. In Comparators dropdown, select an option other than "equals".
	EXPECTED
		* Comparator should reflect the choice you just selected (other than "equals").

	CHECKS: 
		context - 
			Single Choice: 	dependencySelector.js --> Selector() --> singleChoice.js -->	DependencySelector() --> 
							dependencySelector.js --> ChoiceSelector() --> handleChoiceChange() --> dependency.js --> set(context, create(selectedQuestion, choice))
			Text Input: 	dependencySelector.js --> Selector() --> textInput.js --> 		DependencySelector() --> handleComparatorChange() --> dependency.js -> set()
		FormSelect() - components/Select/index.js
		handleValueChange() - This is being called by FormSelect() in response to a onChange event in Select component.
		dependencyUtil.set()
			* This method works fine for Questions dropdown and should be working for the specific question type components.  
				* See dependencySelector.js Selector.handleQuestionChange().
			* In handleComparatorChange(), this is working fine as evidenced by selecting something other than equals, then selecting another item while pausing for debugging.  
				dependencyUtil.get() retrieve the dependency with the new comparator selection evident, so it is an issue of dropdown rendering.
	CAUSE: FormSelect() is using defaultValue parameter when the correct one is value.
		defaultValue - Meant to specify the list item initially selected item.
		value - Meant to specify the list item to selected.
	RESOLUTION:
		* In FormSelect() --> Select component
			ORIGINAL: 
			      <Select
			        labelId={labelId}
			        label={label}
			        value={defaultValue} // <-- Problem Area
			        onChange={handleChange}
			      >
			CHANGE
				  <Select
					labelId={labelId}
					label={label}
					value={value} // RPL042123 - Changed from defaultValue to value.
					onChange={handleChange}
				  >		
*/