// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.

/*eslint-disable*/

import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import {
  timeFormat,
  timeHourFormat,
  valueConvertByComparator,
  Picker,
  PickerRange,
  ComparatorBetween,
} from "./timeInput";
import Debug from "../../services/debug";
import FormSelect from "../../components/Select";
import { QuestionHeader } from "../recipients/question";
import * as config from "../../services/config";
import * as utils from "../../services/utilities";
import * as dependencyUtil from "./dependency";

import {
  updateQuestionAnswer,
  initAnswerObject,
} from "../../services/question";

import customParseFormat from "dayjs/plugin/customParseFormat";
import FormUtility from "../../services/form";
// import useMediaQuery from "../../hooks/useMediaQuery";

dayjs.extend(customParseFormat);
const formUtility = new FormUtility();
export const comparators = [
  "None",
  //"equals", // TODO: Review how visibility will form for a range of time
  //"not", // TODO: Review how visibility will form for a range of time
  "before",
  "after",
  "between",
  //"length", // TODO: 070623 Implement length comparator
];
export const comparatorIsValue = [];
export function New() {
  return {
    type: "TimeInputRange",
    code: null,
    title: "New Time Input Range Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: initAnswerObject(),
    min: DateTime.now().toFormat(timeHourFormat),
    max: DateTime.now().plus({ hour: 1 }).toFormat(timeHourFormat),
  };
}
export function Reset(question) {
  // PURPOSE: Reset user answers specific to this question type.
  utils.assert(question != null, "question is null.");
  question.answer = initAnswerObject();
}

export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component(`TimeInputRange.Edit(${question.code})`, question);
  // #region Assertions
  utils.assert(question != null, "question is null.");
  // #endregion
  // #region Initialize
  formUtility.setDetail(question); // TODO: 071823 Remove dependency on formUtility
  // Validate min/max exists
  const questionMinValid = dayjs(question.min, timeFormat).isValid();
  const questionMaxValid = dayjs(question.max, timeFormat).isValid();

  if (!questionMinValid) {
    // utils.log.info(
    //   `TimeInputRange.Edit(${question.code}) - question.min is invalid: ${question.min} setting to default.`
    // );
    question.min = dayjs().format(timeHourFormat);
  }
  if (!questionMaxValid) {
    // utils.log.info(
    //   `TimeInputRange.Edit(${question.code}) - question.max is invalid: ${question.max} setting to default.`
    // );
    question.max = dayjs().add(1, "hour").format(timeHourFormat);
  }
  const startDefaultValue = question.min;
  const endDefaultValue = question.max;
  // #endregion
  // #region Events
  const handleChange = (timeRange) => {
    const startValue = dayjs(timeRange[0]).format(timeFormat);
    const endValue = dayjs(timeRange[1]).format(timeFormat);

    // utils.log.event(
    //   `TimeInputRange.Edit(${question.code}).handleChange(startValue: ${startValue}, endValue: ${endValue})`,
    //   timeRange
    // );

    question.min = startValue;
    question.max = endValue;
    const startEvent = {
      target: { name: "min", value: startValue },
    };
    const endEvent = {
      target: { name: "max", value: endValue },
    };
    formUtility.handleChange(startEvent, setInputs); // TODO: 062823 Remove dependency on formUtility
    formUtility.handleChange(endEvent, setInputs); // TODO: 062823 Remove dependency on formUtility
  };

  // #endregion

  return (
    <Grid container spacing="2">
      <Grid item xs={12}>
        <Typography
          id="non-linear-slider"
          gutterBottom
          style={{ fontFamily: "Public-sans" }}
        >
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PickerRange
          question={question}
          handleChange={handleChange}
          startDefaultValue={startDefaultValue}
          endDefaultValue={endDefaultValue}
          /*           minValue="00:01"
          maxValue="23:59" */
          hasDisabledTime={false}
        ></PickerRange>
        <Debug value={{ startDefaultValue, endDefaultValue }}></Debug>
      </Grid>
    </Grid>
  );
}
export const DependencySelector = ({context}) => {
  // !NOTE: useState() is prohibited in this component.
  
  // const dependency = dependencyUtil.get(context);
  // const question = dependency.question;
  // const list = utils.toSelectItem(comparators);
  // const comparator = dependency?.comparator ?? list[0].value;

  // // Validate min/max exists
  // const questionMinValid = dayjs(question.min, timeFormat).isValid();
  // const questionMaxValid = dayjs(question.max, timeFormat).isValid();
  // /*   if (!questionMinValid) question.min = dayjs().format(timeHourFormat);
  // if (!questionMaxValid) question.max = dayjs().add(1, "hour"); */
  // const minValue = questionMinValid ? question.min : dayjs().format(timeFormat);
  // const maxValue = questionMaxValid
  //   ? question.max
  //   : dayjs().add(1, "hour").format(timeFormat);

  // if (dependency == null || dependency.value == null) {
  //   // Add a value if it doesn't exist
  //   dependency.comparator = list[0].value;
  //   dependency.value = null;
  // }
  // const value = valueConvertByComparator(dependency, minValue, maxValue);
  // // #endregion
  // // #region Events
  // const handleComparatorChange = (e) => {
  //   const dependency = dependencyUtil.get(context);
  //   const comparator = e.target.value;
  //   dependency.comparator = comparator;
  //   dependencyUtil.set(context, dependency);
  // };
  // const handleChange = (e) => {
    // const value = e?.format(timeFormat);
    // const dependency = dependencyUtil.get(context);
    // dependency.value = value;
    // dependencyUtil.set(context, dependency);
  //   // utils.log.event(`handleChange(${value})`);
  // };
  // #endregion
  return (
    // <>
    //   <FormSelect
    //     id="dateInputRange-comparators"
    //     label="Comparators"
    //     data={list}
    //     defaultValue={comparator}
    //     format={config.defaults.DATE_DISPLAY_FORMAT}
    //     style={{
    //       width: utils.getTextBoxWidth(comparator.length),
    //     }}
    //     onChange={handleComparatorChange}
    //   ></FormSelect>{" "}
    //   <LocalizationProvider dateAdapter={AdapterDayjs}>
    //     {dependency.comparator === "between" && (
    //       <ComparatorBetween
    //         context={context}
    //         minValue={minValue} // string time format
    //         maxValue={maxValue} // string time format
    //       ></ComparatorBetween>
    //     )}
    //     {dependency.comparator !== "between" && (
    //       <Picker
    //         question={question}
    //         handleChange={handleChange}
    //         //dependency={dependency}
    //         defaultValue={value}
    //         minValue={minValue} // string time format
    //         maxValue={maxValue} // string time format
    //         hasDisabledTime={true}
    //       ></Picker>
    //     )}
    //   </LocalizationProvider>
    // </>
    <>
    <DependencySelectorComparatorValue context={context}/>
    </>
  );
};


const DependencySelectorComparatorValue = ({context}) => {

  const dependency = dependencyUtil.get(context);
  const {question , comparator , value} = dependency || {};
  const {  min ,  max} = question;
  const list = utils.toSelectItem(comparators);
  // const comparator = dependency?.comparator ?? list[0].value;
  const [selectedComparator,setSelectedComparator] = useState( comparator ?? list[0].value);
  const [selectedValue,setSelectedValue] = useState(value);
  const [minValue] = useState(min);
  const [maxValue] = useState(max);

  if(!comparator){
    dependency.comparator = "None";
    dependencyUtil.set(context, dependency);
  }
  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    const comparator = e.target.value;
    if(comparator === "None"){
      setSelectedValue(null);
    }
    dependency.comparator = comparator;
    dependencyUtil.set(context, dependency);
    setSelectedComparator(comparator);
  }

  utils.log.info(":: question ^^^",question);

  
  const handleValueChange = (e) => {
    const value = e?.format(timeFormat);
    // const dependency = dependencyUtil.get(context);
    dependency.value = value;
    dependencyUtil.set(context, dependency);
    setSelectedValue(value);
  }

  return(
    <>
      <FormSelect
      // id="dateInputRange-comparators"
      id="dropdownComparators"
      label="Comparators"
      data={list}
      value={selectedComparator}
      format={config.defaults.DATE_DISPLAY_FORMAT}
      style={{
        width: utils.getTextBoxWidth(selectedComparator?.length),
      }}
      onChange={handleComparatorChange}
    />
  <LocalizationProvider dateAdapter={AdapterDayjs}>
      {selectedComparator === "between" && (
        <ComparatorBetween
          context={context}
          minValue={minValue} // string time format
          maxValue={maxValue} // string time format
        ></ComparatorBetween>
      )}
      {selectedComparator !== "None" && selectedComparator !== "between" && (
        <Picker
          question={question}
          handleChange={handleValueChange}
          //dependency={dependency}
          timeSelectedValue={selectedValue}
          setTimeSelectedValue={setSelectedValue}
          minValue={minValue} // string time format
          maxValue={maxValue} // string time format
          hasDisabledTime={true}
        />
      )}
    </LocalizationProvider>
  </>
  )
}


export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}

export function Render(props) {
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  const helperText = "";
  question.answer ??= initAnswerObject();
  // #endregion
  // #region Events
  const handleChange = (timeRange) => {
    if (timeRange === null) return;
    // utils.log.event(`handleChange(timeRange)`, timeRange);
    utils.assert(timeRange.length === 2, "timeRange.length !== 2");

    utils.assert(
      dayjs(timeRange[0]).isValid(),
      `Start(timeRange[0]) is invalid time (expect ${timeFormat})`
    );
    if (timeRange[1] != null) {
      utils.assert(
        dayjs(timeRange[1]).isValid(),
        `End(timeRange[1]) is invalid time (expect ${timeFormat})`
      );
    }

    const startValue = dayjs(timeRange[0])?.format(timeFormat);
    const endValue =
      timeRange[1] == null ? null : dayjs(timeRange[1])?.format(timeFormat);

    const answer = initAnswerObject(startValue, endValue);
    // utils.log.event(`handleChange(${startValue} to ${endValue})`, answer);
    updateQuestionAnswer(question, answer, preview, props.setQuestions);
  };
  const isQuestionCompleted = isCompleted(question);

  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();
  // #endregion

  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
           {question?.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          <div>{helperText}</div>
          {/*           <RenderPicker
            question={question}
            handleChange={handleChange}
          ></RenderPicker> */}
          <PickerRange
            question={question}
            startDefaultValue={question.answer.start}
            endDefaultValue={question.answer.end}
            handleChange={handleChange}
            hasDisabledTime
          ></PickerRange>
        </div>
      </div>
    </>
  );
}

// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.

  if (dependency == null) {
    // utils.log.info(`TimeInputRange.isAnswered(false): dependency is null`);
    return false;
  }
  utils.log.info("^^ dependency in TimeInput Range",dependency);
  const {question , comparator , value} = dependency || {};
  const {answer , min , max} = question || {};
  if(!answer)return false;
  const {start,end} = answer;
  if(!start || !end)return false;

  const currentDate = dayjs().format("YYYY-MM-DD");
  const timeAnswerMin = dayjs(`${currentDate}T${start}`);
  const timeAnswerMax = dayjs(`${currentDate}T${end}`);
  const timeMin = dayjs(`${currentDate}T${min}`);
  const timeMax = dayjs(`${currentDate}T${max}`);
  let timeValue;
  let timeValueMin;
  let timeValueMax;
  if(comparator !== "between"){
   timeValue = dayjs(`${currentDate}T${value}`);
  }else{
    timeValueMin = dayjs(`${currentDate}T${value?.min}`);
    timeValueMax = dayjs(`${currentDate}T${value?.max}`);
  }

  // *** Evaluate ***
  switch (comparator) {
    case "None":
      return isWithinRange(timeAnswerMin,timeAnswerMax,timeMin,timeMax);
    case "equals":
    case "not":
      return handleComparatorEquality(dependency);
    case "before":
    case "after":
    case "between":
      return  isWithinRange(timeAnswerMin,timeAnswerMax,timeMin,timeMax) && handleComparatorPrepositions( comparator ,timeAnswerMin,timeAnswerMax,timeValueMin,timeValueMax,timeValue);
    case "length":
      return handleComparatorLength(dependency);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
// #region Comparators


const isWithinRange = ( timeAnswerMin,timeAnswerMax,timeMin,timeMax) => {
  const isSameOrAfterMin = timeAnswerMin.isSame(timeMin) || timeAnswerMin.isAfter(timeMin);
  const isSameOrBeforeMax = timeAnswerMax.isSame(timeMax) || timeAnswerMax.isBefore(timeMax);
  return isSameOrAfterMin && isSameOrBeforeMax;
};

const handleComparatorEquality = (dependency) => {
  const start = dayjs(dependency.question.answer.start, timeFormat);
  const end = dayjs(dependency.question.answer.end, timeFormat);
  const value = dayjs(dependency.value, timeFormat);

  const resultStartIsSame = value.isSame(start, "minute");
  const resultEndIsSame = value.isSame(end, "minute");
  switch (dependency.comparator) {
    case "equals":
      return resultStartIsSame && resultEndIsSame;
    case "not":
      return !(resultStartIsSame && resultEndIsSame);
    default:
      throw new Error(
        `handleComparatorEquality(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};
const handleComparatorPrepositions = ( comparator ,timeAnswerMin,timeAnswerMax,timeValueMin,timeValueMax,timeValue) => {
  switch (comparator) {
    case "before":
      const resultBefore =
      timeAnswerMin?.isBefore(timeValue) && timeAnswerMax?.isBefore(timeValue);
      return resultBefore;
    case "after":
      const resultAfter =
      timeAnswerMin?.isAfter(timeValue) && timeAnswerMax?.isAfter(timeValue);
      return resultAfter;
    case "between":
      return isWithinRange(timeAnswerMin,timeAnswerMax,timeValueMin,timeValueMax);
      // const isAfterMin =
      //   startObject.isAfter(valueMinObject) ||
      //   startObject.isSame(valueMinObject, "minute");

      // const isBeforeMax =
      //   endObject.isBefore(valueMaxObject) ||
      //   endObject.isSame(valueMaxObject, "minute");

      // const resultBetween = isAfterMin && isBeforeMax;
      // return resultBetween;
    default:
      throw new Error(
        `handleComparatorPrepositions(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
};

const handleComparatorLength = (dependency) => {
  // TODO: 070623 UNTESTED
  debugger;
  const answer = dependency.question.answer;
  const min = dependency.value.min;
  const max = dependency.value.max;
  const length = answer.end.diff(answer.start, "days");
  return length >= min && length <= max;
};

export const isCompleted = (question) => {
  utils.log.info(` :: TimeInputRange.isCompleted(${question.code})`, question);
  if (!question) {
    return false;
  }

  if (!question?.answer?.start || !question?.answer?.end) return false;

  const {
    answer: { start, end },
    min,
    max,
  } = question;

  const dayjsStart = dayjs(start);
  const dayjsEnd = dayjs(end);

  if (dayjsStart.isBefore(dayjs(min)) || dayjsEnd.isAfter(dayjs(max)))
    return false;

  return true;
};

export const getValue = (question) => {
  return {
    start: question.answer.start,
    end: question.answer.end,
  };
};

// #endregion
// #endregion
