/* eslint-disable no-empty */
// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
// BUG(LOW): 072323 - Rule of Hooks error when changing existing question type from something to CategoryChoice.  No real consequences so far.
/*eslint-disable*/

import React, { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
} from "@mui/material";
import FormSelect from "../../components/Select";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import SimpleListEdit from "../../components/Select/SimpleListEdit";
import { ChoiceSelector } from "./dependencySelector";
import * as utils from "../../services/utilities";
// import useMediaQuery from "../../hooks/useMediaQuery";

// PURPOSE: Create Instance of Category Choice Questions
export function New() {
  return {
    type: "CategoryChoice",
    code: null,
    title: "New Category Choice Question",
    description: "",
    instructions: "",
    dependency: null,
    choices: [],
    categories: [],
    answer: [],
  };
}
// PURPOSE: Reset properties specific to this question type.
export function Reset(question) {
  // Here,we generate answer of question again.
  question.answer = generateAnswer(question, true)?.answer;
  // Check if answer of question is correct
  assertCorrectAnswerLength(question);
}
//PURPOSE: Show this Section while creating a Question
export function Edit({ question, setQuestion }) {
  //assertCorrectAnswerLength(question);
  return (
    <SimpleListEdit
      label="Category"
      list={question.categories}
      handleUpdate={setQuestion}
    />
  );
}
const CategorySelector = ({ context, selectedQuestion }) => {
  utils.assert(context != null, "context is null.");
  utils.assert(selectedQuestion != null, "selectedQuestion is null.");
  utils.assert(
    selectedQuestion?.dependency?.question?.categories != null,
    "selectedQuestion?.dependency?.question?.categories is null."
  );
  const dependency = dependencyUtil?.get(context);

  const [selected, setSelected] = useState(dependency?.category);
  const list = utils.toSelectItem(
    selectedQuestion?.dependency?.question?.categories
  );

  const isQuestionSelected = selectedQuestion != null; // Question selected
  const isChoiceSelected = dependency.choice != null; // Choice selected
  const isDisabled = !isQuestionSelected && !isChoiceSelected;

  const handleChange = (e) => {
    const category = e.target.value;
    dependency.category = category === -1 ? null : category;
    dependencyUtil.set(context, dependency);
    setSelected(category);
  };
  return (
      // <div id="dropdownCategories">
        <FormSelect
      value={selected}
      allowNoSelection={utils.noSelection}
      // id="category-select"
      id="dropdownCategories"
      label="Categories"
      disabled={isDisabled}
      data={list}
      onChange={handleChange}
    />
      // </div>
  );
};
// PURPOSE : This is used to create dependency i.e on selct of this category and that choices we
// show/redner a particular Question
export const DependencySelector = ({ context, selectedQuestion }) => {
  // !NOTE: useState() is prohibited in this component.
  return (
    <>
      <ChoiceSelector context={context} selectedQuestion={selectedQuestion} />
      <CategorySelector context={context} selectedQuestion={selectedQuestion} />
    </>
  );
};

// PURPOSE : Create new Dependency
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}

// PURPOSE : Render survey question on Both Recipients page and Preview Page
export function Render(props) {
  utils.assert(props != null, "props is null.");
  utils.assert(props.question != null, "props.question is null.");
  const question = props.question;
  // PURPOSE : CREATE A DISTINCTION,WHETHER WE ARE SHOWING SINGLE QN OR SHOWING ALL QN OF SURVEY
  // preview = true => showing single question
  // preview = false => showing all qn of list
  const preview = props.preview ?? false;
  const validationMessage = "";

  // PURPOSE: TO CHECK IS ANSWER OF QN IS FILLED BY USER
  const isQuestionCompleted = isCompleted(question);
  //PURPOSE: TAKE CHOICES AND CATEGORY FROM QUESTION AND GENERATE ANSWER
  // const questionWithGeneratedAnswer = generateAnswer(question, preview);

  utils.log.info(":: qn in Render", question);
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          <CategoryChoiceQuestion
            preview={preview}
            question={generateAnswer(question, preview)}
            questions={props.questions}
            setQuestions={props.setQuestions}
          />
          {validationMessage}
        </div>
      </div>
    </>
  );
}

export const CategoryChoiceQuestion = ({
  preview = false,
  question,
  questions,
  setQuestions,
}) => {
  utils.assert(question != null, "question is null.");
  utils.assert(setQuestions != null, "setQuestions is null.");
  utils.assert(
    typeof setQuestions === "function",
    "setQuestions is not a function."
  );
  const { choices, categories } = question;
  // PURPOSE: TO RENDER ALL CHOICES AND KNOW WHICH VALUE HAVE BEEN SELECTED AND NOT
  const [answers, setAnswers] = useState(question?.answer);

  const visibleChoices = preview
    ? choices // Show all choices in preview mode
    : choices.filter((c) => c.isVisible);

  // const handleSelected = (selectedItem) => {
  //   const updatedAnswers = answers.map((a) => {
  //     if (
  //       a.category === selectedItem.category &&
  //       a.choice === selectedItem.choice.code
  //     ) {
  //       return {
  //         ...a,
  //         isSelected: !a.isSelected,
  //       };
  //     } else if (
  //       a.choice !== selectedItem.choice.code &&
  //       a.category === selectedItem.category &&
  //       a.isSelected
  //     ) {
  //       return {
  //         ...a,
  //         isSelected: !a.isSelected,
  //       };
  //     } else {
  //       return {
  //         ...a,
  //       };
  //     }
  //   });

  //   let updatedQuestion = {
  //     ...question,
  //     answer: updatedAnswers,
  //   };
  //   setAnswers(updatedAnswers);
  //   if (preview) {
  //     setQuestions(updatedQuestion);
  //   } else {
  //     const updatedQuestions = questions.map((q) => {
  //       if (q.code === updatedQuestion.code) {
  //         return updatedQuestion;
  //       }
  //       return q;
  //     });
  //     setQuestions(updatedQuestions);
  //   }
  // };
  const handleSelected = (selectedItem) => {
    if (preview) {
      setQuestions((prevQuestions) => {
        // Update only the selected question
        const updatedQuestion = {
          ...question,
          answer: question.answer.map((a) => {
            if (
              a.category === selectedItem.category &&
              a.choice === selectedItem.choice.code
            ) {
              return { ...a, isSelected: true };
            } else if (
              a.category === selectedItem.category &&
              a.isSelected &&
              a.choice !== selectedItem.choice.code
            ) {
              return { ...a, isSelected: false };
            }
            return a;
          }),
        };

        return {
          ...prevQuestions,
          answer: updatedQuestion.answer,
        };
      });
    } else {
      setQuestions((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question.code === selectedItem.question?.code) {
            // Update the answer array for the matched question
            const updatedAnswers = question.answer.map((a) => {
              if (
                a.category === selectedItem.category &&
                a.choice === selectedItem.choice.code
              ) {
                return { ...a, isSelected: true };
              } else if (
                a.category === selectedItem.category &&
                a.isSelected &&
                a.choice !== selectedItem.choice.code
              ) {
                return { ...a, isSelected: false };
              }
              return a;
            });

            return {
              ...question,
              answer: updatedAnswers,
            };
          }
          return question; // Keep other questions unchanged
        })
      );
    }
  };

  //PURPOSE: If there is not answer to question or you can say there is either no choices and no category
  // or neither no choices and no category
  if (!question?.answer || !question?.answer?.length) return null;

  return (
    <TableContainer
      component={Paper}
      sx={{ marginLeft: preview ? "0" : "-55px" }}
    >
      <Table
        sx={{ minWidth: 730, background: "#E9E9E9" }}
        aria-label="simple table"
      >
        <TableHead>
          {/* Table Head => Rendering Category  */}
          <TableRow>
            {/* Empty cell for spacing issues */}
            {/* <TableCell> </TableCell> */}
            <TableCell
              sx={{
                padding: "16px 16px",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              Category Choices
            </TableCell>
            {categories.map((category, index) => {
              return (
                <TableCell
                  sx={{
                    fontSize: "12px",
                    padding: "16px 16px",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  key={`headers_${category}_${index}`}
                >
                  {category}
                </TableCell>
              );
            })}
            {/* <TableCell> </TableCell> */}
          </TableRow>
        </TableHead>

        {/* Table Body => Rendering Choices  */}
        <TableBody key={question.code} style={{ border: "none" }}>
          {visibleChoices.map((choice) => {
            return (
              <TableRow
                key={`tablerow_${choice.code}`}
                // sx={{ border: "2px solid blue" }}
              >
                {/* <TableCell sx={{ borderBottom: "none" }}></TableCell> */}
                <TableCell
                  sx={{
                    borderBottom: "none",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {choice?.value || choice?.description}
                </TableCell>

                {categories.map((category, index) => {
                  const current = {
                    question: question,
                    choice: choice,
                    category: category,
                  };
                  const categoryChoice = question?.answer?.find(
                    (ans) =>
                      ans.category === category && ans.choice === choice?.code
                  );
                  return (
                    <TableCell
                      sx={{
                        fontSize: "12px",
                        borderBottom: "none",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      key={`tablecell_${category}_${choice.code}_${index}`}
                    >
                      <CategoryChoice
                        current={current}
                        categoryChoice={categoryChoice}
                        answer={question?.answer}
                        handleSelected={handleSelected}
                        noLabel
                      />
                    </TableCell>
                  );
                })}
                {/* <TableCell sx={{ borderBottom: "none" }}></TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CategoryChoice = ({
  current, // required object { question, choice }
  categoryChoice,
  answer,
  handleSelected, // required function
}) => {
  utils.assert(current != null, "current is null.");
  utils.assert(current.question != null, "current.question is null.");

  const [choiceDependency, setChoiceDependency] = useState(
    current.choice.dependency
  );

  categoryChoice.isVisible = true;

  const isChecked = answer?.find(
    (item) =>
      item?.category === categoryChoice?.category &&
      item?.choice === categoryChoice?.choice
  )?.isSelected;

  const radio = (
    <div>
      <Radio
        size="small"
        key={`category_${current.category}_choice_${current.choice.code}`}
        checked={isChecked}
        value={`category_${current.category}_choice_${current.choice.code}`}
        /* onChange={(e) => handleSelected(e, item)} */
        onClick={() => {
          handleSelected({
            question: current.question,
            choice: current.choice,
            category: current.category,
            type: "choice",
          });
        }}
      />
    </div>
  );

  if (choiceDependency == null) {
    return radio;
  } else {
    if (categoryChoice.isVisible) {
      return radio;
    } else {
      return null;
    }
  }
};

// PURPOSE: Determine if dependency is satisfied.
export function isAnswered(dependency) {
  if (dependency == null) {
    return false;
  }
  const dependencyType = dependencyUtil.getType(dependency);
  utils.log.info(":: dependency of Category Chocie Questions", {
    dependency,
    dependencyType,
  });
  const checkedCount = (answers, scope) => {
    let result = null;
    switch (scope) {
      case "question":
        result = answers.filter((answer) => answer.isSelected).length;
        break;
      case "choice":
        result = answers?.filter((answer) => answer?.isSelected)?.length;
        break;
      case "category":
        result = answers?.filter((answer) => answer?.isSelected)?.length;
        break;
      case "categoryChoice":
        result = answers?.filter(
          (answer) =>
            answer?.category === dependency?.category &&
            answer?.choice === dependency?.choice?.code &&
            answer?.isSelected
        )?.length;
        break;
      default:
        throw new Error(
          `CategoryChoice.isAnswered: Invalid dependency type: ${dependency.type}`
        );
    }
    return result;
  };

  switch (dependencyType) {
    // If there is only  question => no choices and no category
    case "question":
      // TODO(LOW): When we figure out how to restore survey functions, convert checkedCount to that.
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        return true;
      }
      break;
    // if there is only choice along with question => no category
    case "choice":
      const answersWithSameChoice = dependency?.question?.answer?.filter(
        (ans) => ans?.choice === dependency?.choice?.code
      );
      if (checkedCount(answersWithSameChoice, dependencyType) > 0) {
        return true;
      }
      break;

    // if tehre is only categroy along with question => no category
    case "category":
      const answersWithSameCategory = dependency?.question?.answer?.filter(
        (ans) => ans?.category === dependency?.category
      );
      if (checkedCount(answersWithSameCategory, dependencyType) > 0) {
        return true;
      }
      break;
    // if tehre is both  category and choice along with category Choice questions
    case "categoryChoice":
      if (checkedCount(dependency.question.answer, dependencyType) > 0) {
        return true;
      }
      break;
    default:
      throw new Error(
        `CategoryChoice.isAnswered: Invalid dependency type: ${dependency.type}`
      );
  }
  return false;
}

// PURPOSE: Determine if question has correct number of answers.
const assertCorrectAnswerLength = (question) => {
  if (
    question.categories.length > 0 && // has categories
    question.choices.length > 0 // has choices
  ) {
    const maxAnswers = question.categories.length * question.choices.length;
    utils.assert(
      question.answer.length === maxAnswers,
      `Incorrect number of answers in question.  Expect: ${maxAnswers} Actual: ${question.answer.length}`
    );
  }
};

export const generateAnswer = (question, preview = true) => {
  // if (!preview) return question;
  // if( question?.answer?.length > 0 ) {
  //   return question;
  // }
  // generate question only when there is no answers
  if (
    !question?.answer ||
    question?.choices?.length * question?.categories?.length !==
      question?.answer?.length
  ) {
    // Populate question.answer with an array of objects { category, choice, isSelected }
    question.answer = [];
    question.choices.forEach((choice) => {
      question.categories.forEach((category) => {
        question.answer.push({
          category: category,
          choice: choice.code,
          isSelected: false,
          isVisible: true,
        });
      });
    });
    assertCorrectAnswerLength(question);
    return question;
  }else{
    return question;
  }
  //  else if (question?.answer?.length > 0) {
  //   return question;
  // }else{
  //   return question
  // }
};

// PURPOSE: DETERMINE IF QUESTIONS IS COMPLETED OR NOT
export const isCompleted = (question) => {
  const onlyVisibleOptions = question?.answer?.filter(
    (answer) => answer?.isVisible
  );
  if (onlyVisibleOptions?.length === 0) return false;

  const allSelectedCategory = question?.categories?.filter((category) =>
    question?.answer?.find(
      (ans) => ans?.category === category && ans?.isSelected
    )
  );
  const hasAllCategorySelected =
    allSelectedCategory?.length === question?.categories?.length;
  // check is any of the visible options is selected
  // const hasSelected = onlyVisibleOptions?.some((answer) => answer?.isSelected);
  return Boolean(hasAllCategorySelected);
};

export const getValue = (question) => {
  return {
    values: question?.answer
      ?.filter((element) => element.isSelected)
      .map((element) => ({
        category: element.category,
        value: element.choice,
      })),
  };
};

//const question = getQuestion(survey.questions, current.question);

// Effects
/*   
useEffect(() => {

    if (isVisible) {
      handleVisibleCategories(current.category, "add");
    } else handleVisibleCategories(current.category, "remove");
  }, [survey]); 
  */
// Visibility relies on dependency being answered
// if (!categoryChoice.isVisible && choiceDependency != null) {
// TODO: Review with other question types if !question.isVisible (as in above line) is still necessary

// if (choiceDependency == null && categoryChoice?.isVisible) {

// categoryChoice.isVisible = true;

// } else {
//choiceDependency.category = current.category; // REVIEW: Why is this happening? RPL080823 Removed due to adding category when not needed.
// Check if dependency is visible to override visibility settings
// !REVIEW 072223 - Marked code below as possibly not needed
/*     categoryChoice.isVisible = isDependencyAnswered(
      question,
      choiceDependency,
      survey
    ); */
// }

/* export const resolveDependency = (question, target) => {
  return question.resolveDependency(target);
};
 */

// #endregion Question functions
// Currently we remove this dependecny
const questionAddFunctionality = (question) => {
  // PURPOSE: IT ADD ISANSWERED TO (question.answer) TO KNOW WHETHER THE ANSWER IS FILLED BY USER OR NOT
  // => CHOICE IS SELECTED OR NOT
  question.answer.isAnswered = function (type, value) {
    return question.answer.filter((answer) => {
      return (
        answer[type] === value &&
        //answer.choice === choice.code &&
        answer.isSelected === true
      );
    });
  };
  question.answer.get = function (category, choice) {
    // PURPOSE: Get a specific category/choice
    const result = question.answer.find(
      (answer) => answer.category === category && answer.choice === choice
    );

    return result;
  };
  question.answer.selectChoiceByCategory = (selectedItem) => {
    // PURPOSE: Set choice.isSelected to true by category
    // utils.log.debug(`selectChoiceByCategory(${selectedItem.category})`);
    const categoryAnswers = question.answer.filter(
      (answer) => answer.category === selectedItem.category //&& answer.choice === selectedItem.choice.code
    );
    //answers.setIsSelected(false); //resetIsSelected(answers, false);
    question.setIsSelected(categoryAnswers, false);
    const answer = categoryAnswers.find(
      (answer) => answer.choice === selectedItem.choice.code
    );

    answer.isSelected = true;

    return answer;
  };

  question.isComplete = (() => {
    // All category/choice must have a selected choice

    const onlyVisibleQuestions = question.answer.filter(
      (answer) => answer.isVisible
    );
    if (onlyVisibleQuestions.length === 0) return false;
    for (let a = 0; a < onlyVisibleQuestions.length; a++) {
      // iterate through categories
      const category = question.answer[a].category;

      if (question.answer.isAnswered("category", category).length !== 1) {
        // FAIL: At least one category has no selection.
        return false;
      }
    }
    // PASS: Each category must have one choice selected.
    return true;
  })();
  question.resolveDependency = function (source, target) {
    // utils.log.debug(`resolveDependency(${source.code}, ${target.code})`);
    if (target.category == null && target.choice != null) {
      // *** Choice dependency ***
      const selectedChoices = target.question.answer.isAnswered(
        "choice",
        target.choice.code
      );
      const selectedChoicesResult = selectedChoices.length > 0;
      //if (selectedChoicesResult) debugger;
      return selectedChoicesResult; // PASS: At least one choice is selected in categories
    }
    if (target.category != null && target.choice == null) {
      // TODO: Untested
      // *** Category dependency ***

      const selectedCategories = question.answer.isAnswered(
        target.question,
        "category",
        target.choice.code
      );
      return selectedCategories.count === 1;
    }
    if (target.category != null && target.choice != null) {
      // TODO: UNTESTED
      // *** Category/Choice dependency ***
      return question.answer.get(target.category, target.choice.code)
        .isSelected;
    }
  };
  question.category = function (category) {};
  question.setIsSelected = function (list, state) {
    // PURPOSE: Sets the isSelected property of list members to state (bool).

    // utils.log.info(
    //   `setIsSelected(state: ${state} list: ${list.length}) BEFORE`,
    //   list
    // );

    /*     for (var i = 0; i < list.length; i++) {
      list[i].isSelected = state;
    } */
    list.forEach((item) => {
      item.isSelected = state;
    });
    // utils.log.info(
    //   `setIsSelected(state: ${state} list: ${list.length}) AFTER`,
    //   list
    // );
  };
};

//  CategoryChoice Components

/*   const categoryChoice = question.answer.get(
    current.category,
    current.choice.code
  ); */
//assertCorrectAnswersLength(question);
// #region Initialize
//const choiceDependency = current.choice.dependencies?.[0];
/*eslint-disable-next-line*/

// utils.log.info("??CCQ-CategoryChoice",{current});
// PURPOSE: Renders a single choice for a CategoryChoice question type.
// utils.log.component("CategoryChoice",{current,answer,categoryChoice})
// utils.log.disabled(
//   `CategoryChoice(category: ${current.category} choice: ${current.choice.code})`,
//   current
// );
// #region Assertions

// CATEGORY CHOICE QUESTIONS  COMPONENTS

/*   useEffect(() => {
    // utils.log.useEffect(
      `CategoryChoiceQuestion(${question.code}) - categories: ${categories}`,
      question
    );
    const options = categories.reduce((prev, curr) => {
      prev[curr] = "";
      return prev;
    }, {});
    setSelectedColumns(options);
    //assertCorrectAnswersLength(question);
    // utils.log.stateChange(
      `CategoryChoiceQuestion(${
        question.code
      }) - selectedColumns: ${JSON.stringify(selectedColumns)}`,
      selectedColumns
    );
  }, [categories]); */
// const handleSelected = (selectedItem) => {
//     const updatedAnswers = answers.map((a) => {
//       if (
//         a.category === selectedItem.category &&
//         a.choice === selectedItem.choice.code
//       ) {
//         return {
//           ...a,
//           isSelected: !a.isSelected,
//         };
//       }
//       return {
//         ...a,
//       };
//     });
//     // utils.log.info(":: updatedAnswers",selectedItem);

//     let updatedQuestion = {
//       ...question,
//       answer: updatedAnswers,
//     };
//     if(preview){
//       setQuestions(updatedQuestion);
//     }else{
//     questionAddFunctionality(updatedQuestion);
//     setAnswers(updatedAnswers);

//     const updatedQuestions = questions.map((q) => {
//       if (q.code === updatedQuestion.code) {
//         return updatedQuestion;
//       }
//       return q;
//     });

//     setQuestions(updatedQuestions);
//   }
// };
